<template>
    <div class="result">
        <div class="result-main">
            <div class="result-title">
                <h2>第四届“探界·达人秀”泌尿青年医师手术&病例挑战赛</h2>
                <i>{{ title }}</i>
                <h3>优胜战队</h3>
            </div>
            <div class="result-head">
                <div class="result-carry" v-for="(item,index) in list">
                    <div class="carry-head">
                        <img :src="item.battles_img3 || 'https://image.ysxxlm.cn/ysxxlm/pc/static/img/my.png'"/>
                    </div>
                    <div class="carry-name">{{ item.player_name }}<p>{{ item.player_hospital_name }}</p></div>
                    <div class="pwpf-info">
                        <div class="pwpf-info-item">
                            <p>评委评分：{{ item.judges_score|fixed2 }}</p>
							<p>专区投票：{{item.vote_score1}}</p>
							<p>观众分： {{item.vote_score|fixed2}}</p>
							<p>现场直播间投票:{{ item.vote_score2}}</p>
                            <!-- <p>手术部分得分:{{ item.judges_score1 }}</p>
                            <p>病例部分得分:{{ item.judges_score2 }}</p> -->
                            <p>抢答得分：{{ item.number }}</p>
                            <p>最终得分：{{ item.score|fixed2 }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                title: '',
                list: []
            }
        },
		filters:{
			fixed2(val){
			  return val?Number(val.toFixed(2)):val
			}
		},
        mounted() {
            this.getList()
        },
        methods: {
			
            getList() {
                this.$axios.post(this.apiUrl.baiji23ResultSelf,{group_id:this.$route.query.group_id}).then(res => {
                    this.list = res.data
                    this.title = res.msg
                })
            }
        }
    }
</script>
<style scoped lang="scss">
    .result{
        background: url("~@/assets/img/baiji/bj2023-1.jpg") no-repeat;
        background-size: 100% 100%;
        min-height: 100vh;
        .result-main{/*
            background: rgba(000,000,000,.3);*/
            /*position: fixed;*/
            /*left: 0;*/
            /*top: 0;*/
            /*right: 0;*/
            /*bottom: 0;*/
            text-align: center;
			padding-top: 1px;
            .result-title{
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                font-weight: bold;
                font-family:SimHei;
                color: #bf3763;
                margin-top: 40px;
                h2{
                    font-size: 35px;
                }
                i{font-size: 35px;margin: 10px 0}
                h3{
                    font-size: 50px;
                    text-align: center;
                    font-weight: bold;
                    font-family: PangMenZhengDao;
                    text-transform:uppercase;
                    color:#bf3763;
                    text-shadow:
                            0px 1px 0px #c0c0c0,
                            0px 2px 0px #C4C4C4,
                            0px 3px 0px #CACACA,
                            0px 4px 0px #CFCFCF,
                            0px 3px 5px rgba(255, 255, 255, 0.7);
                    letter-spacing:3px;

                }
            }
            .result-head{
                margin-top: 10px;
                .result-carry{
                    vertical-align: top;
                    margin: 0 2%;
                }
            }
            .result-carry{
                display: inline-block;
                box-sizing: border-box;
                position: relative;
                .carry-ranking{
                    position: absolute;
                    right: 0px;
                    top: 0px;
                    width: 50px;
                    height: 50px;
                }
                .carry-head{
                    width: 280px;
                    height: 280px;
                    background: url("https://image.ysxxlm.cn/ysxxlm/pc/static/img/tximg.png") no-repeat;
                    background-size: 100% 100%;
                    padding: 25px;
                    margin: 0 auto;
                    img{
                        width: 100%;
                        height: 100%;
                        border-radius: 100%;
                    }
                }
                .carry-name{
                    text-align: center;
                    font-size: 38px;
                    font-weight:bold;
                    margin-bottom: 10px;
                    color: #bf3763;
                    margin-top: 10px;
                }
            }
        }
    }
    .pwpf-info{
        text-align: left;
        .pwpf-info-item{
            padding: 5px 0;
            font-size: 24px;
        }

        p{margin: 0}
    }

</style>
